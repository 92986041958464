.nav {
  padding: 2rem 6rem;
}

.nav-links {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  font-size: 1.8rem;
}

.nav-links a.dark {
  color: #fff;
  text-decoration: none;
}

.nav-links a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease; /* Transição suave para o efeito de escala */
}

.nav-links a:hover {
  transform: scale(1.2); /* Aumenta a tag para 120% no hover */
}

.nav-links a.light {
  color: #000;
  text-decoration: none;
}

.theme-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease; /* Transição suave para o efeito de escala */
}

.theme-switcher:hover {
  transform: scale(1.2); /* Aumenta o ícone para 120% no hover */
}

@media (max-width: 640px) {
  .nav-links {
    gap: 2rem;
  }
}

@media (max-width: 1200px) {
  .nav-links {
    justify-content: center;
  }

  .avatar {
    width: 18rem;
    height: 18rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .button {
    display: grid;
    margin-top: 1.2rem;
    margin-right: 0;
  }

  .button:active {
    background-color: #396a9f;
    color: white;
  }

  .button:hover {
    background-color: #396a9f;
    color: white;
  }
}