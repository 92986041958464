#root {
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app {
  margin-bottom: 4rem;
}

.app.light {
  background-color: white;
  color: rgba(44, 41, 36, 0.846);
}

html {
  font-size: 62.5%;
}

html.dark {
  background-color: #1e1e1e;
  color: blanchedalmond;
}

html.light {
  background-color: white;
  color: rgba(44, 41, 36, 0.846);
}

body {
  font-family: "Poppins", sans-serif;
}

body,
p {
  font-size: min(1.2rem, 1.6rem);
}

/* Estilo do contêiner de carregamento */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
}

/* Estilo do spinner com tamanho maior */
.spinner {
  border: 6px solid #f3f3f3;
  /* Cor de fundo do spinner */
  border-top: 6px solid #3498db;
  /* Cor da parte animada do spinner */
  border-radius: 50%;
  width: 100px;
  /* Aumentando o tamanho */
  height: 100px;
  /* Aumentando o tamanho */
  animation: spin 2s linear infinite;
}

/* Animação do spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Estilo de texto abaixo do spinner */
.loading-text {
  margin-top: 20px;
  /* Espaçamento entre o spinner e o texto */
  font-size: 18px;
  /* Tamanho maior do texto */
  color: #333;
  /* Cor do texto */
  font-weight: bold;
  /* Deixar o texto mais forte */
}